import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import css from "./style.module.css"

export default ({ albums }) => (
  <>
    <div className={css.content}>
      {albums.map(({ node: { id, name, cover, fields: { slug } } }) => (
        <div key={id} className={css.album}>
          <Link key={id} to={slug} className={css.image}>
            <Img fluid={cover.childImageSharp.fluid} />
            <div>{name}</div>
          </Link>
        </div>
      ))}
    </div>

    {/* <div className="main-page-content">printGalleryDesc</div> */}
  </>
)
