import React from "react"
import { graphql } from "gatsby"
import Layout from "src/component/Layout"
import GalleryPage from "src/component/GalleryPage"

const galleryName = "portfolio"

export default ({ data }) => {
  const gallery = { title: galleryName, url: `/${galleryName}` }
  return (
    <Layout gallery={gallery}>
      <GalleryPage albums={data.albums.edges} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PortfolioQuery {
    albums: allDirectory(
      filter: {
        sourceInstanceName: { eq: "portfolio" }
        relativePath: { ne: "" }
      }
    ) {
      edges {
        node {
          id
          name
          fields {
            slug
          }
          cover {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
